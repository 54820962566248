import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import useFooterMenuItems from "../../hooks/useFooterMenuItems"
import FooterLogo from "../Icons/FooterLogo"
import FooterPrimaryNavItem from "./FooterPrimaryNavItem"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import useCopyrightMenuItems from "../../hooks/useCopyrightMenuItems"
import CopyrightNavItem from "./CopyrightNavItem"

interface IFooterInfo {
  address: string
  email: {
    title: string
    url: string
  }
  phone: {
    title: string
    url: string
  }
}

const FooterInfo: React.FC<IFooterInfo> = ({ address, email, phone }) => {
  const footerNavData = useFooterMenuItems()
  const copyrightNavItems = useCopyrightMenuItems()

  const joinWifiText = "Join Free WiFi"
  const emailBHMText = email?.title
  const copyrightText = "Copyright"
  const bhamAirportText = "Birmingham Airport Authority"

  const [text, setText] = useState({
    bhamAirportText,
    joinWifiText,
    emailBHMText,
    copyrightText,
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({
        bhamAirportText,
        joinWifiText,
        emailBHMText,
        copyrightText,
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          bhamAirportText,
          joinWifiText,
          emailBHMText,
          copyrightText,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <>
      <div css={[tw`px-7 pr-9 z-10 mt-16 sm:px-12 lg:px-16 2xl:pr-48`]}>
        <div css={[tw`flex flex-col pb-5 lg:flex-row`]}>
          <FooterLogo
            styling={tw`h-20 lg:h-auto absolute lg:relative -mt-16 lg:-mt-0 z-0`}
          />
          <ul
            css={[
              tw`pt-12 max-w-[2000px] sm:flex-grow sm:flex sm:justify-between lg:mx-auto`,
            ]}
          >
            {footerNavData?.map(({ node: { label, path } }) => (
              <FooterPrimaryNavItem label={label} url={path} key={label} />
            ))}
          </ul>
        </div>
        <div css={[tw`text-lg xl:text-base`]}>
          <address css={[tw`not-italic`]}>
            <p dangerouslySetInnerHTML={{ __html: address }} />
          </address>
          <div css={[tw`text-blue-250 -ml-1`]}>
            <a
              href={email?.url}
              css={[
                tw`inline-block px-1 no-underline hover:underline hover:text-blue-600 focus:text-blue-600 focus:outline-none focus-visible:border-dashed focus-visible:border focus-visible:border-blue-300 focus-visible:rounded-sm`,
              ]}
            >
              {text?.emailBHMText}
            </a>
            <p css={[tw`inline-block px-2`]}>|</p>
            <a
              href={phone?.url}
              css={[
                tw`inline-block px-1 hover:underline hover:text-blue-600 focus:text-blue-600 focus:outline-none focus-visible:border-dashed focus-visible:border focus-visible:border-blue-300 focus-visible:rounded-sm`,
              ]}
            >
              {phone?.title}
            </a>
          </div>
          <p css={[tw`my-7`]}>
            {text?.joinWifiText}{" "}
            <span css={[tw`text-blue-250 font-medium`]}>BHMFREEWIFI</span>
          </p>
        </div>
        <div css={[tw`text-xs sm:text-sm`]}>
          <p>
            &copy; {text?.copyrightText} {new Date().getFullYear()}{" "}
            {text?.bhamAirportText}
            <span css={tw`mx-2`}>|</span>
          </p>
          {copyrightNavItems?.map(({ label, url }, i: number) => (
            <div key={i}>
              {i !== 0 && <span css={tw`mx-2`}>|</span>}
              <CopyrightNavItem label={label} url={url} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default FooterInfo
