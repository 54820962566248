import React, { useState, useEffect } from "react"
import tw from "twin.macro"

import { Footer } from "../Footer"
import Header from "../Header/Header"

import "../../global.css"
import "@fontsource/mulish/200.css"
import "@fontsource/mulish/300.css"
import "@fontsource/mulish"
import "@fontsource/mulish/500.css"
import "@fontsource/mulish/600.css"
import "@fontsource/mulish/700.css"
import "@fontsource/mulish/800.css"

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [mobileMenu, setMobileMenu] = useState(false)

  // Close mobileMenu if open when window is resized to desktop view
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.innerWidth >= 1280) {
        setMobileMenu(false)
      }
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  // useEffect(() => {
  //   const gatsbyFocusWrapper = document.getElementById("gatsby-focus-wrapper")
  //   if (gatsbyFocusWrapper) {
  //     gatsbyFocusWrapper.removeAttribute("style")
  //     gatsbyFocusWrapper.removeAttribute("tabIndex")
  //   }
  // }, [])

  return (
    <>
      <Header mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
      <main
        id="main"
        tabIndex={0}
        css={[mobileMenu && tw`hidden`]}
        style={{ outlineColor: "#AED1D3", outlineOffset: "8px" }}
      >
        {children}
      </main>
      <Footer mobileMenu={mobileMenu} />
    </>
  )
}

export default Layout
